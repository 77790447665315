import React, { useContext } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { LoginContext } from '../../utils/context'
import { useLangue } from '../../utils/hooks'

import './myaccount.css'

function BankAccount() {
  const { langue } = useLangue()
  const { isLoggedIn } = useContext(LoginContext)

  return (
    isLoggedIn && (
      <div className="myaccount" langue={langue}>
        <ion-icon name="swap-horizontal-outline"></ion-icon>
        <div className="items">
          <Link to="operations" className="link">
            {langue === 'english' ? 'My Account' : 'Minha Conta'}
          </Link>

          <Link to="datas" className="link">
            {langue === 'english' ? 'RIB' : 'RIB'}
          </Link>
        </div>
        <Outlet />
      </div>
    )
  )
}

export default BankAccount
