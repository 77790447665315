import React from 'react'
import { Link } from 'react-router-dom'
import ServicePart1 from '../../components/Services'
import ServicePart2 from '../../components/ServicesItems'
import Testimonials from '../../components/Testimonials'
import './service.css'
import { useLangue } from '../../utils/hooks'

function Services() {
  const { langue } = useLangue()

  return (
    <div className="serviceWrapper" langue={langue}>
      <div className="service_top">
        <div className="service_top_color">
          <h1 className="service_top_h1">
            {langue === 'english' ? 'Service' : 'Servizi'}
          </h1>
          <div className="service_top_div">
            <Link to="/" className="home_link">
              {langue === 'english' ? 'Home /' : 'Início /'}
            </Link>
            <p>{langue === 'english' ? 'Service' : 'Servizi'}</p>
          </div>
        </div>
      </div>
      <div>
        <ServicePart1 />
      </div>
      <div>
        <ServicePart2 />
      </div>
      <div>
        <Testimonials />
      </div>
    </div>
  )
}

export default Services
