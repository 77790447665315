import React from 'react'
import './services.css'
import { useLangue } from '../../utils/hooks'

function Services() {
  const { langue } = useLangue()
  return (
    <div className="service" langue={langue}>
      <div>
        <h1 className="service_part_1_h1">
          {langue === 'english' ? 'Nossos serviços' : 'Os nossos serviços'}
        </h1>
        <div className="service_part_1_h1_bottom"></div>
        <div className="service_part_2">
          <div style={{ display: 'flex' }}>
            <ion-icon name="home-outline"></ion-icon>

            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'All types of credit'
                  : 'Todos os tipos de crédito'}
              </a>
              <p>
                {langue === 'english'
                  ? 'A financing offer adapted to all your business creation, university studies, travel, real estate acquisition or construction projects…'
                  : 'Uma oferta de financiamento adaptada a todas as suas criações de negócios, estudos universitários, viagens, aquisições imobiliárias ou projetos de construção...'}
              </p>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <ion-icon name="chatbubbles"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'Quick response within an hour'
                  : 'Resposta rápida em uma hora'}
              </a>
              <p>
                {langue === 'english'
                  ? 'As financing experts, we are able to offer you different solutions and guide you towards the one best suited to your needs.'
                  : 'Como especialistas em financiamento, somos capazes de oferecer-lhe diferentes soluções e orientá-lo para a mais adequada às suas necessidades.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="card"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'No additional fees'
                  : 'Sem taxas adicionais'}
              </a>
              <p>
                {langue === 'english'
                  ? 'We guarantee you the most competitive fees in the sector and will not charge you any additional fees for studying your file.'
                  : 'Garantimos-lhe as taxas mais competitivas do setor e não lhe cobraremos quaisquer taxas adicionais para estudar o seu processo.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="finger-print"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'Guaranteed security'
                  : 'Segurança garantida'}
              </a>
              <p>
                {langue === 'english'
                  ? 'All information that passes through our website is protected with end-to-end encryption.'
                  : 'Todas as informações que passam pelo nosso site são protegidas com criptografia de ponta a ponta.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="sunny"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'The best investment'
                  : 'O melhor investimento'}
              </a>
              <p>
                {langue === 'english'
                  ? 'Our success depends on yours because we consider that our customers are our best investment.'
                  : 'O nosso sucesso depende do seu, pois consideramos que os nossos clientes são o nosso melhor investimento.'}
              </p>
            </div>
          </div>

          <div style={{ display: 'flex' }}>
            <ion-icon name="trophy-outline"></ion-icon>
            <div className="service_part_2_content">
              <a href="/#">
                {langue === 'english'
                  ? 'Succeed and recommend us'
                  : 'Tenha sucesso e recomende-nos'}
              </a>
              <p>
                {langue === 'english'
                  ? 'The best advertising is what our customers do. Dare to take the big step that will lead you to success and nourish our notoriety.'
                  : 'A melhor publicidade é aquela que os nossos clientes fazem. Atreva-se a dar o grande passo que o levará ao sucesso e alimente a nossa notoriedade.'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
