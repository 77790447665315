import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import Testimonials from '../../components/Testimonials'
import Services from '../../components/Services'
import WhyChooseUs from '../../components/WhyChooseUs'
import NeedLoan from '../../components/NeedLoan'

// import Iphone from '../../assets/home/iphone.png'
import IphoneEn from '../../assets/home/EN.png'
import IphonePt from '../../assets/home/PT.png'
import { LoginContext } from '../../utils/context'
import './userDashbord.css'
import { useLangue } from '../../utils/hooks'

function UserDashbord() {
  const { langue } = useLangue()
  const { isLoggedIn } = useContext(LoginContext)

  return (
    isLoggedIn && (
      <main className="user_dashbord" langue={langue}>
        <div className="info">
          <h1 className="info_title">
            {langue === 'english' ? 'Info' : 'Informações'}
          </h1>
          <div>
            <marquee
              align="middle"
              // height="50"
              loop="infinie"
              scrollamount="4"
              className="marquee"
            >
              {langue === 'english'
                ? 'Asly grants loans, from €5,000 to €70,000,000 in less than 72 hours over a repayment period of 01 to 30 years, with an interest rate of 2.5% per year.'
                : 'A Asly concede empréstimos, de €5.000 a €70.000.000 em menos de 72 horas, durante um período de reembolso de 01 a 30 anos, com uma taxa de juros de 2,5% ao ano.'}
            </marquee>
          </div>
        </div>
        <div style={{ marginTop: '-25px' }} className="whyChooseUs">
          <WhyChooseUs />
        </div>

        <div>
          <Services />
        </div>

        <div>
          <NeedLoan />
        </div>

        <div className="home_part_4">
          <div className="home_part_4_left">
            <div className="home_part_4_left_1">
              <h2 className="home_part_4_left_1_h2">
                {langue === 'english'
                  ? 'Our different features'
                  : 'Nossos recursos diferentes'}
              </h2>
              <div className="home_part_4_left_1_div">
                <div className="item">
                  {langue === 'english'
                    ? 'Quick Loans Online'
                    : 'Empréstimos Rápidos Online'}
                </div>
                <div className="item">
                  {langue === 'english'
                    ? 'Instant Transfers'
                    : 'Transferências Instantâneas'}
                </div>
                <div className="item">
                  {langue === 'english'
                    ? 'Automatic Savings'
                    : 'Economia Automática'}
                </div>
                <div className="item">
                  {langue === 'english'
                    ? 'Personalized Alerts'
                    : 'Alertas Personalizados'}
                </div>
                <div className="item">
                  {langue === 'english'
                    ? 'Smart Investments'
                    : 'Investimentos Inteligentes'}
                </div>
                <div className="item">
                  {langue === 'english'
                    ? 'Secure Virtual Cards'
                    : 'Cartões Virtuais Seguros'}
                </div>
                <div className="item">
                  {langue === 'english'
                    ? 'Rewards Program'
                    : 'Programa de Recompensas'}
                </div>
                <div className="item">
                  {langue === 'english'
                    ? 'Full Mobile Access'
                    : 'Acesso Total pelo Celular'}
                </div>
              </div>
            </div>
            <div className="home_part_4_left_2">
              <h2 className="home_part_4_left_2_h2">
                {langue === 'english'
                  ? 'Are you sure of your abilities?'
                  : 'Tem certeza de suas habilidades?'}
              </h2>
              <p className="home_part_4_left_2_p">
                {langue === 'english'
                  ? 'A loan binds you and must be repaid. Check your repayment capacity before you commit.'
                  : 'Um empréstimo o vincula e deve ser pago. Verifique sua capacidade de pagamento antes de se comprometer.'}
              </p>
              <div className="home_part_4_btn_container">
                <Link to="/loan" className="home_part_4_btn">
                  {langue === 'english' ? 'Start now' : 'Comece agora'}
                </Link>
              </div>
            </div>
          </div>
          <img
            // src={Iphone}
            src={langue === 'english' ? IphoneEn : IphonePt}
            alt="iphone"
            height="800em"
            className="home_part_4_img"
          />
        </div>

        <div>
          <Testimonials />
        </div>
      </main>
    )
  )
}

export default UserDashbord
