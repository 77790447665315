import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './needLoan.css'
import { LoginContext } from '../../utils/context'
import { useLangue } from '../../utils/hooks'

function NeedLoan(props) {
  const { langue } = useLangue()
  const { isLoggedIn } = useContext(LoginContext)
  return (
    <div className="home_part_3" langue={langue}>
      <div className="home_part_3_color">
        <h2 className="home_part_3_h2">
          {langue === 'english'
            ? 'Do you need a loan for your project?'
            : 'Hai bisogno di un prestito per il tuo progetto?'}
        </h2>
        <p className="home_part_3_p">
          {langue === 'english'
            ? 'Asly grants loans, from €5,000 to €70,000,000 in less than 72 hours over a repayment period of 01 to 30 years, with an interest rate of 2.5% per year.'
            : "Asly concede prestiti, da €5.000 a €70.000.000 in meno di 72 ore, con un periodo di rimborso da 01 a 30 anni, con un tasso di interesse del 2,5% all'anno."}
        </p>
        {!isLoggedIn ? (
          <div className="home_part_3_btn_div">
            <Link
              href=""
              onClick={props.scrollToLoan}
              className="home_part_3_btn"
            >
              {langue === 'english'
                ? 'Request a loan now'
                : 'Richiedi un prestito ora'}
            </Link>
          </div>
        ) : (
          <div className="home_part_3_btn_div">
            <Link to="/loan" className="home_part_3_btn">
              {langue === 'english' ? 'Start now' : 'Inizia ora'}
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default NeedLoan
