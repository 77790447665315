import testimonials_1 from '../assets/testimonial/testimonials-1.jpg'
import testimonials_2 from '../assets/testimonial/testimonials-2.jpg'
import testimonials_3 from '../assets/testimonial/testimonials-3.jpg'
import testimonials_4 from '../assets/testimonial/testimonials-4.jpg'
import testimonials_5 from '../assets/testimonial/testimonials-5.jpg'

export const testimonialsList = [
  {
    name: 'Paul Dubois',
    profil: 'Teacher',
    description:
      'Getting a loan with Asly was a seamless and quick experience. Their professional team answered all my questions and made the process simple and efficient.',
    picture: testimonials_1,
    description_pt:
      "Ottenere un prestito con Asly è stata un'esperienza rapida e senza problemi. Il loro team di professionisti ha risposto a tutte le mie domande e ha reso il processo semplice ed efficiente",
    profil_pt: 'Insegnante',
  },
  {
    name: 'Claire Martinez',
    profil: 'Architect',
    description:
      'Asly made my dream of owning a home come true. Their team guided me through every step of the loan process, providing exceptional service and great terms.',
    picture: testimonials_2,
    description_pt:
      'Asly ha realizzato il mio sogno di possedere una casa di proprietà. Il loro team mi ha guidato in ogni fase del processo di prestito, fornendo un servizio eccezionale e condizioni eccellenti',
    profil_pt: 'Architetto',
  },
  {
    name: 'Fatima Al-Mansoori',
    profil: 'Entrepreneur',
    description:
      'As a young entrepreneur, Asly offered me a tailor-made loan that stimulated the growth of my business. Their commitment to small businesses is remarkable.',
    picture: testimonials_3,
    description_pt:
      'Come giovane imprenditore, Asly mi ha offerto un prestito personalizzato che ha stimolato la crescita della mia azienda. Il loro impegno nei confronti delle piccole imprese è notevole',
    profil_pt: 'Imprenditore',
  },
  {
    name: 'James Harper',
    profil: 'IT consultant',
    description:
      'Asly was able to understand my financial needs. Thanks to their flexibility and competitive rates, I was able to consolidate my debts and save on interest.',
    picture: testimonials_4,
    description_pt:
      'Asly è stata in grado di capire le mie esigenze finanziarie. Grazie alla sua flessibilità e ai tassi competitivi, ho potuto consolidare i miei debiti e risparmiare sugli interessi',
    profil_pt: 'Consulente informatico',
  },
  {
    name: 'Alejandro Garcia',
    profil: 'Lawyer',
    description:
      "Asly's auto loan allowed me to buy the car of my dreams without hassle. The process was simple, and their team demonstrated exceptional professionalism.",
    picture: testimonials_5,
    description_pt:
      "Il prestito auto di Asly mi ha permesso di acquistare l'auto dei miei sogni senza alcuna complicazione. Il processo è stato semplice e il team è stato eccezionalmente professionale",
    profil_pt: 'Avvocato',
  },
]
