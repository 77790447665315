import React, { useContext, useEffect, useState } from 'react'
import { LangueContext } from '../../utils/context'
import './footer.css'
import { useLangue } from '../../utils/hooks'

function Footer() {
  const { langue, setLangue } = useContext(LangueContext)
  const [selectedLangue, setSelectedLangue] = useState(() => {
    const savedLangue = localStorage.getItem('langue')
    return savedLangue || langue
  })

  const handleLangueChange = (event) => {
    setSelectedLangue(event.target.value)
    setLangue(event.target.value)
    localStorage.setItem('langue', event.target.value)
  }

  return (
    <div className="footerContainer">
      <div className="footerWrapper" langue={langue}>
        <div className="footer_top">
          <div className="footer_top_col_1">
            <h1 style={{ marginBottom: '15px' }}>
              {langue === 'english' ? 'Asly' : 'Asly'}
            </h1>
            <p>
              {langue === 'english'
                ? 'The company Asly Bank SAS is registered on the Single Register of Intermediaries in Insurance, Banking and Finance (ORIAS), in the category Broker in banking operations and payment services (COBSP) is subject to the ACPR, the prudential supervisory authority and resolution.'
                : "La società Asly Bank SAS è registrata nel Registro Unico degli Intermediari in Assicurazioni, Banche e Finanza (ORIAS), nella categoria Broker in operazioni bancarie e servizi di pagamento (COBSP) è soggetta all'ACPR, l'autorità di vigilanza prudenziale e risoluzione."}
            </p>
            <ion-icon name="logo-twitter"></ion-icon>
            <ion-icon name="logo-facebook"></ion-icon>
            <ion-icon name="logo-instagram"></ion-icon>
            <ion-icon name="logo-linkedin"></ion-icon>
          </div>
          <div className="footer_top_col">
            <h2 style={{ marginBottom: '15px' }}>
              {langue === 'english' ? 'Discover us' : 'Scoprire'}
            </h2>
            <a href="/about" className="link">
              {langue === 'english' ? 'About' : 'Chi siamo'}
            </a>
            <br />
            <a href="/team" className="link">
              {langue === 'english' ? 'Leading team' : 'Squadra leader'}
            </a>
            <br />
            <a href="/#testimonials" className="link">
              {langue === 'english' ? 'Testimonials' : 'Testimonianze'}
            </a>
            <br />
          </div>
          <div className="footer_top_col">
            <h2 style={{ marginBottom: '15px' }}>
              {langue === 'english' ? 'Learn more' : 'Per saperne di più'}
            </h2>
            <a href="/bank_account_info" className="link">
              {langue === 'english' ? 'Bank account' : 'Conto bancario'}
            </a>
            <br />
            <a href="/legal_notice" className="link">
              {langue === 'english' ? 'Legal Notice' : 'Avviso legale'}
            </a>
            <br />
            <a href="/confidentiality" className="link">
              {langue === 'english' ? 'Confidentiality' : 'Riservatezza'}
            </a>
            <br />
          </div>
          <div className="footer_top_col">
            <h2 style={{ marginBottom: '15px' }}>
              {langue === 'english' ? 'Contact us' : 'Contattaci'}
            </h2>
            <p>Viale Giuseppe Duodo, 84, 33100 Udine UD</p>
            <p>
              <strong>E-mail</strong>: contact@aslyonline.com
            </p>
          </div>
        </div>
        <div className="langueBoxContainer">
          <select value={selectedLangue} onChange={handleLangueChange}>
            <option value="" disabled selected hidden class="text-muted ">
              Choose language
            </option>
            <option disabled value="">
              Choose language
            </option>
            {/* <option value="portuguais">Português</option> */}
            <option value="italian">Italiano</option>
            <option value="english">English</option>
          </select>
        </div>
      </div>
      <div className="footer_bottom">
        <p>
          {' '}
          &copy; {langue === 'english'
            ? 'Copyright'
            : "Diritto d'autore"} BMP.{' '}
          {langue === 'english'
            ? 'All rights reserved'
            : 'Tutti i diritti riservati'}
        </p>
        <p>{langue === 'english' ? 'Designed by' : 'Progettato da'} Credas </p>
      </div>
    </div>
  )
}

export default Footer
