import React from 'react'
import { Link } from 'react-router-dom'
import './legalNotice.css'
import { useLangue } from '../../utils/hooks'

function LegalNotice() {
  const { langue } = useLangue()

  return (
    <div className="legalNoticeWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {langue === 'english' ? 'Legal notice' : 'Avviso Legale'}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {langue === 'english' ? 'Home /' : 'Inizio /'}
            </Link>
            <p>{langue === 'english' ? 'Legal notice' : 'Avviso Legale'}</p>
          </div>
        </div>
      </div>
      <div className="legalNotice">
        <h1 className="legalNotice_h1">
          {langue === 'english'
            ? 'Legal Notice - Asly Online Banking'
            : 'Avviso Legale - Asly Banco Online'}
        </h1>
        <div className="legalNotice_h1_bottom"></div>

        <div className="legalNotice_content">
          <div>
            <p>
              {langue === 'english'
                ? 'Name of the Bank: Asly Banque en Ligne S.A.'
                : 'Nome della Banca: Asly Banque en Ligne S.A.'}
            </p>
            <p>
              {langue === 'english'
                ? 'Registered Address: Av.da República 57,1050-189 Lisbon, Portugal'
                : 'Indirizzo Registrato: Av.da República 57,1050-189 Lisbona, Portogallo'}
            </p>
            <p>Email: contact@aslyonline.com</p>
            <p>
              {langue === 'english'
                ? 'Terms of Service:'
                : 'Termini di Servizio:'}
            </p>
            <p>
              {langue === 'english'
                ? 'By accessing this website, you accept the General Conditions of Use of Asly Online Bank. Please read them carefully before continuing.'
                : 'Accedendo a questo sito web, accetti le Condizioni Generali di Utilizzo di Asly Banque en Ligne. Leggere attentamente prima di proseguire.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Intellectual property:'
                : 'Proprietà Intellettuale:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'The content of this site, including but not limited to text, images, graphics, logos and other elements, is protected by intellectual property laws and is the exclusive property of Asly Banque en Ligne or its licensors license.'
                : 'Il contenuto di questo sito, compresi ma non limitati a testi, immagini, grafica, loghi e altri elementi, è protetto dalle leggi sulla proprietà intellettuale ed è di esclusiva proprietà di Asly Banque en Ligne o dei suoi licenzianti.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Protection of personal data:'
                : 'Protezione dei dati personali:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Asly Online Bank is committed to protecting the privacy of its users in accordance with its Privacy Policy. By using this site, you consent to the processing of your personal data as described in this policy.'
                : 'Asly Banque en Ligne si impegna a proteggere la privacy dei suoi utenti in conformità con la sua Politica sulla Privacy. Utilizzando questo sito, acconsenti al trattamento dei tuoi dati personali come descritto in questa politica.'}
            </p>
          </div>

          <div>
            <h2>{langue === 'english' ? 'Cookies:' : 'Cookies:'}</h2>
            <p>
              {langue === 'english'
                ? 'This site uses cookies to improve user experience. By continuing to browse this site, you accept the use of cookies in accordance with our Cookies Policy.'
                : "Questo sito utilizza i cookie per migliorare l'esperienza dell'utente. Continuando a navigare su questo sito, accetti l'utilizzo dei cookie in conformità con la nostra Politica sui Cookie."}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english' ? 'External links:' : 'Link esterni:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'This site may contain links to third party websites. Asly Online Bank assumes no responsibility for the content or privacy policy of these sites.'
                : 'Questo sito può contenere collegamenti a siti web di terze parti. Asly Banque en Ligne non assume alcuna responsabilità per il contenuto o la politica sulla privacy di questi siti.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Applicable Law and Jurisdiction:'
                : 'Legge applicabile e giurisdizione:'}
            </h2>
            <p>
              {langue === 'english'
                ? 'This legal notice is subject to German law. Any dispute arising from the use of this site will be subject to the exclusive jurisdiction of the courts of Berlin.'
                : "Questo avviso legale è soggetto alla legge tedesca. Ogni controversia derivante dall'utilizzo di questo sito sarà di competenza esclusiva dei tribunali di Berlino."}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegalNotice
