import React from 'react'
import { teamList } from '../../data/team'
import './team.css'
import { useLangue } from '../../utils/hooks'

function Team() {
  const { langue } = useLangue()
  return (
    <div className="team" langue={langue}>
      <h1 className="team_h1">
        {langue === 'english' ? 'Our Team' : 'Nossa Equipa'}
      </h1>
      <div className="team_h1_bottom"></div>

      <div className="team_container">
        {teamList.map((person, index) => (
          <div key={`${person.name}-${index}`}>
            <img src={person.image} alt="img" height="250vh" while="auto" />
            <h3>{person.name}</h3>
            <p>{langue === 'english' ? person.title : person.title_pt}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Team
