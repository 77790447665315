import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './login.css'
import { useLangue } from '../../utils/hooks'

function Login() {
  const { langue } = useLangue()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [userId, setUserId] = useState('')
  const currentTime = new Date().getTime()

  const checkEmptyData = () => {
    if (password === '' || email === '') {
      setError(
        langue === 'english' ? 'No empty fields !' : 'Nessun campo vuoto !'
      )
    } else if (password !== '' && email !== '') {
      setError('')

      const token = localStorage.getItem('token')

      if (!token) {
        fetch(`https://api-asly-1.onrender.com/api/auth/login`, {
          // fetch(`http://127.0.0.1:8001/api/auth/login`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        })
          .then((response) => response.json())
          .then((user) => {
            if (user.token) {
              localStorage.setItem('token', user.token)
              localStorage.setItem('userRole', user.isAdmin)
              localStorage.setItem('firstName', user.firstName)
              localStorage.setItem('lastName', user.lastName)
              localStorage.setItem('tokenExpiration', currentTime)

              setUserId(user.userId)
              window.location.href = '/user_dashbord'
            } else {
              setError(
                langue === 'english'
                  ? user.message ||
                      'Login failed. Please check your credentials.'
                  : 'Accesso non riuscito. Controlla le tue credenziali.'
              )
            }
          })
          .catch((error) => {
            setError(
              langue === 'english'
                ? 'An error occurred while logging in.'
                : "Si è verificato un errore durante l'accesso."
            )
          })
      }
    }
  }

  const connectUsers = () => {
    checkEmptyData()
  }

  return (
    <div className="loginContainer" langue={langue}>
      <div className="login_sub_container">
        <div className="login_left">
          <h1 className="login-title-1">Asly Bank</h1>
          <h2 className="login-title-2">
            {langue === 'english'
              ? 'For your satisfaction'
              : 'Per la tua soddisfazione'}
          </h2>
          <h3 className="login-title-3">
            {langue === 'english' ? 'Welcome' : 'Benvenuto'}
          </h3>
          <p className="login-title-message">
            {langue === 'english'
              ? 'Siamo lieti di darti il benvenuto nuovamente! La protezione dei tuoi dati personali è di estrema importanza per noi, e desideriamo garantire la sicurezza delle tue informazioni durante la navigazione sulla nostra piattaforma.'
              : 'Siamo felici di darti il benvenuto nuovamente! La protezione dei tuoi dati personali è di estrema importanza per noi, e desideriamo garantire la sicurezza delle tue informazioni durante la navigazione sulla nostra piattaforma.'}
          </p>
        </div>
        <div className="login_form">
          <h1 className="login-title-4">
            {langue === 'english' ? 'Connection' : 'Connessione'}
          </h1>
          <div>
            <label className="login-label">
              {langue === 'english' ? 'Email' : 'E-mail'}
            </label>
            <input
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              className="login-input"
              type="email"
            />
          </div>
          <div>
            <label className="login-label">
              {langue === 'english' ? 'Password' : 'Password'}
            </label>
            <input
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              className="login-input"
              type="password"
            />
          </div>
          <div>
            <input
              className=""
              type="checkbox"
              defaultChecked
              style={{
                marginRight: '5px ',
                accentColor: 'rgba(0, 0, 255, 0.656)',
              }}
            />
            {langue === 'english' ? 'Remember me' : 'Ricordami'}
          </div>
          <h3 style={{ color: 'red' }}> {error} </h3>

          <Link
            onClick={(e) => {
              e.preventDefault()
              connectUsers()
            }}
            to=""
            className="connect_me login_btn"
          >
            {langue === 'english' ? 'Connection' : 'Connessione'}
          </Link>

          <div className="forgot_password">
            <a href="#notAvailable">
              {langue === 'english'
                ? 'Forgot your password ?'
                : 'Hai dimenticato la password ?'}
            </a>

            <Link to="/signup">
              {langue === 'english'
                ? 'Create an account !'
                : 'Crea un account !'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
