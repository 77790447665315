import React from 'react'
// bibliothèques de slides
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useLangue } from '../../utils/hooks'

// import WhyUs from '../../assets/home/why-us-bg.jpg'
import './chooseUs.css'

function WhyChooseUs() {
  const { langue } = useLangue()
  const settings = {
    dots: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
  }

  return (
    <div className="home_part_2">
      <h1 className="home_part_2_h1">
        {langue === 'english' ? 'Why choose us ?' : 'Por que nos escolher ?'}
      </h1>
      <div className="home_part_2_h1_bottom"></div>
      <div className="slider_part">
        <div className="home_part_2_img_container">
          {/* <img src={WhyUs} alt="WhyUs" className="home_part_2_img" /> */}
        </div>
        <div className="slide_wrapper">
          <Slider {...settings}>
            <div className="slider_content">
              <h2 className="slider_content_h2">
                {langue === 'english'
                  ? 'Protecting your Finances'
                  : 'Protegendo suas Finanças'}
              </h2>
              <h3 className="slider_content_h3">
                {langue === 'english'
                  ? 'Robust Security and 24/7 Surveillance'
                  : 'Segurança robusta e vigilância 24 horas por dia, 7 dias por semana'}
              </h3>
              <p className="slider_content_p">
                {langue === 'english'
                  ? "Enjoy a borderless banking experience. Access your accounts anytime, from anywhere in the world, with our easy-to-use platform. Whether you're at home, on the move or abroad, manage your finances with ease."
                  : 'Desfrute de uma experiência bancária sem fronteiras. Acesse suas contas a qualquer momento, de qualquer lugar do mundo, com nossa plataforma fácil de usar. Esteja você em casa, em movimento ou no exterior, gerencie suas finanças com facilidade.'}
              </p>
            </div>
            <div className="slider_content">
              <h2 className="slider_content_h2">
                {langue === 'english'
                  ? 'The future of our Bank'
                  : 'O futuro do nosso banco'}
              </h2>
              <h3 className="slider_content_h3">
                {langue === 'english'
                  ? 'Advanced Solutions and Smart Services'
                  : 'Soluções avançadas e serviços inteligentes'}
              </h3>
              <p className="slider_content_p">
                {langue === 'english'
                  ? 'Discover innovation in every transaction. Our online banking offers advanced technology solutions such as financial analysis tools, user-friendly applications and integrated services, giving you a modern and efficiency-driven banking experience.'
                  : 'Descubra a inovação em cada transação. Nosso banco online oferece soluções tecnológicas avançadas, como ferramentas de análise financeira, aplicativos fáceis de usar e serviços integrados, proporcionando uma experiência bancária moderna e orientada para a eficiência.'}
              </p>
            </div>
            <div className="slider_content">
              <h2 className="slider_content_h2">
                {langue === 'english'
                  ? 'Your Satisfaction, Our Commitment'
                  : 'Sua Satisfação, Nosso Compromisso'}
              </h2>
              <h3 className="slider_content_h3">
                {langue === 'english'
                  ? 'Responsive and Personalized Customer Support'
                  : 'Suporte ao Cliente Responsivo e Personalizado'}
              </h3>
              <p className="slider_content_p">
                {langue === 'english'
                  ? 'We are committed to providing exceptional customer service. Our dedicated team is available to answer your questions, resolve your issues and support you every step of the way. We value every customer, and your satisfaction is at the heart of our mission.'
                  : 'Estamos comprometidos em fornecer um serviço excepcional ao cliente. Nossa equipe dedicada está disponível para responder às suas perguntas, resolver seus problemas e apoiá-lo em cada etapa do caminho. Valorizamos cada cliente, e sua satisfação está no centro de nossa missão.'}
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs
