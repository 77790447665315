import React from 'react'
import { Link } from 'react-router-dom'
import './confidentiality.css'
import { useLangue } from '../../utils/hooks'

function Confidentiality() {
  const { langue } = useLangue()
  return (
    <div className="confidentialityWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {langue === 'english'
              ? 'Privacy Policy'
              : 'Informativa sulla Privacy'}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {langue === 'english' ? 'Home /' : 'Início /'}
            </Link>
            <p>
              {langue === 'english'
                ? 'Privacy Policy'
                : 'Informativa sulla Privacy'}
            </p>
          </div>
        </div>
      </div>
      <div className="confidentiality">
        <h1 className="confidentiality_h1">
          {langue === 'english'
            ? 'Asly Online Bank Privacy Policy'
            : 'Informativa sulla Privacy della Banca Online Asly'}
        </h1>
        <div className="confidentiality_h1_bottom"></div>

        <div className="confidentiality_content">
          <div>
            <p>
              {langue === 'english'
                ? 'Effective Date:'
                : 'Data di Entrata in Vigore:'}{' '}
              <strong style={{ fontWeight: '500' }}>18 settembre 1999</strong>
            </p>
            <p>
              {langue === 'english'
                ? "Asly Online Bank is committed to protecting the privacy of its customers. This privacy policy explains how we collect, use, disclose and protect our customers' personal information."
                : 'La Banca Online Asly si impegna a proteggere la privacy dei suoi clienti. Questa informativa sulla privacy spiega come raccogliamo, usiamo, divulghiamo e proteggiamo le informazioni personali dei nostri clienti.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'I. Collection of personal information'
                : 'I. Raccolta di informazioni personali'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We collect the following personal information to provide online banking services and improve your experience:'
                : 'Raccogliamo le seguenti informazioni personali per fornire servizi bancari online e migliorare la vostra esperienza:'}{' '}
            </p>
            <ul>
              <li>
                {langue === 'english'
                  ? 'Identifying information (last name, first name, address, telephone number, etc.)'
                  : 'Informazioni di identificazione (cognome, nome, indirizzo, numero di telefono, ecc.)'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Financial information (accounts, transactions, history, etc.)'
                  : 'Informazioni finanziarie (conti, transazioni, storico, ecc.)'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Connection information (email, password, etc.)'
                  : 'Informazioni di connessione (email, password, ecc.)'}
              </li>
            </ul>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'II. Use of information'
                : 'II. Uso delle informazioni'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We use personal information to:'
                : 'Utilizziamo le informazioni personali per:'}{' '}
            </p>
            <ul>
              <li>
                {langue === 'english'
                  ? 'Providing online banking services'
                  : 'Fornire servizi bancari online'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Manage customer accounts and process transactions'
                  : 'Gestire i conti dei clienti e elaborare le transazioni'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Personalize the user experience'
                  : "Personalizzare l'esperienza utente"}
              </li>
              <li>
                {langue === 'english'
                  ? 'Respond to customer service requests'
                  : 'Rispondere alle richieste di assistenza clienti'}
              </li>
              <li>
                {langue === 'english'
                  ? 'Improve our products and services'
                  : 'Migliorare i nostri prodotti e servizi'}
              </li>
            </ul>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'III. Disclosure of information'
                : 'III. Divulgazione di informazioni'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We only share your personal information with third parties in the following cases:'
                : 'Condividiamo le vostre informazioni personali solo con terzi nei seguenti casi:'}{' '}
            </p>
            <ul>
              <li>
                {langue === 'english'
                  ? 'When necessary to provide the services requested by the customer'
                  : 'Quando necessario per fornire i servizi richiesti dal cliente'}
              </li>
              <li>
                {langue === 'english'
                  ? 'To comply with applicable laws and regulations'
                  : 'Per rispettare le leggi e i regolamenti applicabili'}
              </li>
              <li>
                {langue === 'english'
                  ? 'With the explicit consent of the customer'
                  : 'Con il consenso esplicito del cliente'}
              </li>
            </ul>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'IV. Information security'
                : 'IV. Sicurezza delle informazioni'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We take appropriate security measures to protect personal information from unauthorized access, alteration, disclosure or destruction.'
                : 'Adottiamo misure di sicurezza adeguate per proteggere le informazioni personali da accessi non autorizzati, alterazioni, divulgazioni o distruzioni.'}{' '}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'V. Cookies and similar technologies'
                : 'V. Cookies e tecnologie simili'}
            </h2>
            <p>
              {langue === 'english'
                ? 'We use cookies and other similar technologies to improve navigation and personalize the online experience. Customers can manage their cookie preferences.'
                : "Utilizziamo i cookie e altre tecnologie simili per migliorare la navigazione e personalizzare l'esperienza online. I clienti possono gestire le loro preferenze sui cookie."}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'VI. Privacy Policy Updates'
                : 'VI. Aggiornamenti della Politica sulla Privacy'}
            </h2>
            <p>
              {langue === 'english'
                ? 'This privacy policy may be updated periodically. Customers will be informed of any significant changes.'
                : 'Questa informativa sulla privacy può essere aggiornata periodicamente. I clienti saranno informati di eventuali cambiamenti significativi.'}
            </p>
          </div>

          <div>
            <h2>{langue === 'english' ? 'VII. Contact' : 'VII. Contatto'}</h2>
            <p>
              {langue === 'english'
                ? 'For questions regarding the privacy policy, please contact us at: contact@aslyonline.com.'
                : "Per domande riguardanti l'informativa sulla privacy, vi preghiamo di contattarci all'indirizzo: contact@aslyonline.com."}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Confidentiality
