import team_1 from '../assets/team/team-1.jpg'
import team_2 from '../assets/team/team-2.jpg'
import team_3 from '../assets/team/team-3.jpg'
import team_4 from '../assets/team/team-4.jpg'

export const teamList = [
  {
    name: 'Sven Schmid',
    title: 'Bank Director',
    image: team_1,
    title_pt: 'Diretor do Banco',
    title_pt: 'Direttore della Banca',
  },
  {
    name: 'Simone Fuhrmann',
    title: 'Bank manager',
    image: team_2,
    title_pt: 'Gerente de banco',
    title_pt: 'Direttore di banca',
  },
  {
    name: 'Matthias Zweig',
    title: 'Bank notary',
    image: team_3,
    title_pt: 'Notário bancário',
    title_pt: 'Notaio bancario',
  },
  {
    name: 'Martina Hofmann',
    title: 'Bank Secretary',
    image: team_4,
    title_pt: 'Secretário do Banco',
    title_pt: 'Segretario della Banca',
  },
]
