import React from 'react'
import { Link } from 'react-router-dom'
import WhyChooseUs from '../../components/WhyChooseUs'
import NeedLoan from '../../components/NeedLoan'
import Team from '../../components/Team'
import './about.css'
import { useLangue } from '../../utils/hooks'

import AboutImg from '../../assets/about/about.jpg'

function About() {
  const { langue } = useLangue()
  return (
    <div className="aboutWrapper" langue={langue}>
      <div className="about_top">
        <div className="about_top_color">
          <h1 className="about_top_h1">
            {langue === 'english' ? 'About' : 'Chi siamo'}
          </h1>
          <div className="about_top_div">
            <Link to="/" className="about_link">
              {langue === 'english' ? 'Home /' : 'Casa /'}
            </Link>
            <p>{langue === 'english' ? 'About' : 'Chi siamo'}</p>
          </div>
        </div>
      </div>
      <div className="about_part_1">
        <div className="about_part_1_img">
          <img src={AboutImg} alt="about" />
        </div>
        <div className="about_part_1_des">
          <h1>{langue === 'english' ? 'Who are we ?' : 'Quem somos nós ?'}</h1>
          <h3>
            {langue === 'english'
              ? 'Created in 2010, Asly Bank positions itself as a fully online bank specializing in offering different types of credit to its customers.'
              : "Creato nel 2010, Asly Bank si posiziona come una banca completamente online specializzata nell'offerta di diversi tipi di credito ai suoi clienti."}
          </h3>
          <p>
            {langue === 'english'
              ? 'We offer an innovative experience to lead your finances and take control of your wealth management. Securely and across all channels. Asly Bank is aimed at an active, mobile and independent clientele, wishing to take the reins of their personal financial management. We provide you with a complete range: everyday banking products, a savings solution, personalized services, as well as support available at any time on our platform.'
              : "Offriamo un'esperienza innovativa per guidare le tue finanze e prendere il controllo della gestione del tuo patrimonio. In modo sicuro e su tutti i canali. Asly Bank è rivolto a una clientela attiva, mobile e indipendente, desiderosa di prendere in mano la gestione delle proprie finanze personali. Ti offriamo una gamma completa: prodotti bancari quotidiani, una soluzione di risparmio, servizi personalizzati, oltre a supporto disponibile in qualsiasi momento sulla nostra piattaforma."}
          </p>
        </div>
      </div>
      <div>
        <WhyChooseUs />
      </div>
      <div>
        <NeedLoan />
      </div>
      <div>
        <Team />
      </div>
    </div>
  )
}

export default About
