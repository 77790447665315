import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './contact.css'
import { useLangue } from '../../utils/hooks'

function Contact() {
  const { langue } = useLangue()
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  const checkEmptyData = (data) => {
    for (const key in data) {
      if (data[key] === '') {
        setError(
          langue === 'english' ? 'No empty fields!' : 'Nessun campo vuoto!'
        )
        return false
      }
    }
    return true
  }

  const handleSubmit = () => {
    if (checkEmptyData(formData)) {
      fetch(`https://api-asly-1.onrender.com/api/loan/contact-admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (response.ok) {
            navigate('/contact')
            setError('')
            setFormData({
              name: '',
              email: '',
              subject: '',
              message: '',
            })
            setSuccess(
              langue === 'english'
                ? 'Your message has been sent successfully!'
                : 'Il tuo messaggio è stato inviato con successo!'
            )
          } else {
            setSuccess('')
            setError(
              langue === 'english'
                ? 'Something is wrong. Refresh the page and try again.'
                : 'Qualcosa è andato storto. Aggiorna la pagina e riprova.'
            )
          }
        })
        .catch((error) => {
          console.error('Error sending email:', error)
        })
    } else {
      setError(
        langue === 'english' ? 'No empty fields!' : 'Nessun campo vuoto!'
      )
    }
  }

  return (
    <div className="contactWrapper" langue={langue}>
      <div className="contact_top">
        <div className="contact_top_color">
          <h1 className="contact_top_h1">
            {langue === 'english' ? 'Contact' : 'Contatto'}
          </h1>
          <div className="contact_top_div">
            <Link to="/" className="home_link">
              {langue === 'english' ? 'Home /' : 'Inizio /'}
            </Link>
            <p>{langue === 'english' ? 'Contact' : 'Contatto'}</p>
          </div>
        </div>
      </div>
      <div className="contact_bottom">
        <div>
          <h1>
            <ion-icon name="location"></ion-icon>{' '}
            {langue === 'english' ? 'Location' : 'Posizione'}
          </h1>
          <p>Viale Giuseppe Duodo, 84, 33100 Udine UD</p>

          <h1>
            <ion-icon name="mail"></ion-icon> E-mail
          </h1>
          <p>contact@alsyonline.com</p>
        </div>
        <div className="contact_bottom_form">
          <h3
            style={{
              color: 'green',
              textAlign: 'center',
              marginBottom: '10px',
            }}
          >
            {success}
          </h3>
          <div className="contact_bottom_form_top" style={{ margin: '0' }}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="register-input"
              placeholder={langue === 'english' ? 'Your Name' : 'Il Tuo Nome'}
            />

            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="register-input"
              placeholder={langue === 'english' ? 'Your Email' : 'La Tua Email'}
            />
          </div>
          <div>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
              className="register-input style_input_2"
              placeholder={langue === 'english' ? 'Subject' : 'Soggetto'}
            />
          </div>
          <div>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              className="register-input style_input_2"
              placeholder={
                langue === 'english' ? 'Your Message' : 'Il Tuo Messaggio'
              }
            ></textarea>
          </div>
          <h3 style={{ color: 'red', textAlign: 'center' }}> {error} </h3>
          <Link
            onClick={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
            to=""
            className="contact_btn"
          >
            {langue === 'english' ? 'Send Message' : 'Invia Messaggio'}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Contact
