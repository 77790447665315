import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Loader } from '../../utils/style/loader'
import './adminDashbord.css'

function AdminDashbord({ users }) {
  const [creditAmount, setCreditAmount] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const token = localStorage.getItem('token')
  const [allUsers, setUsers] = useState({})
  const [isLoading, setIsLoading] = useState(true)

  // const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleCreditLinkClick = (user) => {
    setSelectedUser(user)
    setShowModal(true)
  }

  const handleCreditSubmit = async (event) => {
    event.preventDefault()

    const amount = parseFloat(creditAmount)
    if (isNaN(amount) || amount <= 0) {
      alert('Please inter a valide amount')
      return
    }

    try {
      const response = await fetch(
        `https://api-asly-1.onrender.com/api/auth/user/${selectedUser._id}`,
        // `http://localhost:8001/api/auth/user/${selectedUser._id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            solde: amount,
          }),
        }
      )

      if (!response.ok) {
        throw new Error('An error occurred while processing the request.')
      }
      // Afficher un message de confirmation
      alert(
        `Le compte de ${selectedUser.firstName} ${selectedUser.lastName} a été crédité de ${creditAmount} €.`
      )
      setShowModal(false) // Fermer la boîte de dialogue modale
      setSelectedUser(null)
      setCreditAmount('')
      navigate('/display_users')
    } catch (error) {
      throw new Error('An error occurred while processing the request.')
      // navigate('/display_users')
    }
  }

  useEffect(() => {
    if (token) {
      fetch(`https://api-asly-1.onrender.com/api/auth/users`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Inclure le token JWT dans l'en-tête Authorization
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUsers(data)
          setIsLoading(false) // Mettre isLoading à false une fois les données chargées
        })
        .catch((error) => {
          // console.error('Error fetching user data:', error)
          setIsLoading(false) // isLoading à false même en cas d'erreur
        })
    } else {
      localStorage.clear()
      navigate('/login')
    }
  }, [token, navigate]) // token dans les dépendances pour recharger les données lorsque user token change

  return isLoading ? (
    <div style={{ margin: '145px 0', width: '100%' }}>
      <Loader />
    </div>
  ) : (
    <div className="users_wrapper">
      <h2>All clients list</h2>
      {Object.values(allUsers).map((user) => (
        <div key={user._id} className="users_item">
          <div style={{ width: '30%' }}>
            {user.firstName} {user.lastName}
          </div>
          <div style={{ width: '20%' }}> € {user.solde} </div>
          <div className="link_container" style={{ width: '40%' }}>
            <Link className="link" onClick={() => handleCreditLinkClick(user)}>
              Credit account
            </Link>{' '}
            . <Link className="link">Show profile</Link>
          </div>
        </div>
      ))}

      {/* pop up */}

      {showModal && (
        <div className=" popupBackground">
          {/* <div className="modal popupBackground"> */}
          {/* <div className="modal-content popup"> */}
          <div className=" popup">
            <span className="close" onClick={() => setShowModal(false)}>
              &times;
            </span>
            <h2>Créditer le compte de {selectedUser.firstName}</h2>
            <form onSubmit={handleCreditSubmit} className="popup_form">
              <label>Montant à créditer:</label>
              <input
                type="number"
                name="creditAmount"
                value={creditAmount}
                onChange={(e) => setCreditAmount(e.target.value)}
              />
              <button type="submit" className="popup_btn " id="btnEnvoyerMail">
                Valider
              </button>
            </form>
          </div>
        </div>
      )}
      {/* end pop up */}
    </div>
  )
}

export default AdminDashbord
