import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Loader } from '../../utils/style/loader'
import { useLangue } from '../../utils/hooks'
import './loan.css'

function LoanForm() {
  const { langue } = useLangue()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState('')

  const [loanData, setLoanData] = useState({
    object: '',
    amount: '',
    period: '',
    rate: '',
    name: '',
    email: '',
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setLoanData((prevData) => ({ ...prevData, [name]: value }))
  }

  const handleLinkClick = () => {
    const text =
      langue === 'english'
        ? "By applying for a loan here, you agree to your personal data being shared with the bank's loan department so that your application can be processed. If the request is accepted, you will be contacted via the e-mail address you provided."
        : "Sottoscrivendo un prestito qui, acconsenti alla condivisione dei tuoi dati personali con il reparto prestiti della banca per consentire l'elaborazione della tua richiesta. Se la richiesta è accettata, sarai contattato tramite l'indirizzo e-mail fornito."

    Swal.fire({
      title:
        langue === 'english' ? 'Important message!' : 'Messaggio importante!',
      text: text,
      icon: 'info',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
      showCancelButton: false,
    })
  }

  const checkEmptyData = () => {
    if (
      loanData.object === '' ||
      loanData.amount === '' ||
      loanData.name === '' ||
      loanData.email === ''
    ) {
      setIsLoading(false)
      setError(
        langue === 'english' ? 'No empty fields!' : 'Nessun campo vuoto!'
      )
      return false
    }
    setError('')
    return true
  }

  const trustEmail = (email) => {
    let emailRegExp = new RegExp('[a-z0-9._-]+@[a-z0-9._-]+\\.[a-z0-9._-]+')
    if (!emailRegExp.test(email)) {
      setIsLoading(false)
      setError(langue === 'english' ? 'Invalid email!' : 'Email non valida!')
      return false
    }
    return true
  }

  const handleSubmit = () => {
    if (!checkEmptyData() || !trustEmail(loanData.email)) {
      return
    }
    setIsLoading(true)
    setError('')

    fetch(`https://api-asly-1.onrender.com/api/loan/loan-request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loanData),
    })
      .then((response) => {
        if (response.ok) {
          setIsLoading(false)
          navigate('/')
          setError('')
          setLoanData({
            object: '',
            amount: '',
            period: '',
            rate: '',
            name: '',
            email: '',
          })
          setSuccess(
            langue === 'english'
              ? 'Your request has been sent successfully!'
              : 'La tua richiesta è stata inviata con successo!'
          )
        } else {
          setIsLoading(false)
          setError(
            langue === 'english'
              ? 'Something is wrong. Refresh the page and try again.'
              : 'Qualcosa è andato storto. Aggiorna la pagina e riprova.'
          )
        }
      })
      .catch((error) => {
        console.error('Error sending email:', error)
      })
  }

  return (
    <div
      className="loanFormWrapper loanAskingWrapper"
      style={{ marginTop: '77px' }}
      langue={langue}
    >
      <h1>
        {langue === 'english'
          ? 'Loan amortization table'
          : 'Tabella di ammortamento del prestito'}
      </h1>
      <div className="loan_info">
        {langue === 'english' ? 'Important! Click ' : 'Importante! Clicca '}
        <Link onClick={handleLinkClick} className="alert">
          {langue === 'english' ? 'here' : 'qui'}
        </Link>{' '}
        {langue === 'english' ? 'first.' : 'prima.'}
      </div>
      <p className="loan_info">
        {langue === 'english'
          ? 'Please complete this form carefully to apply for a loan!'
          : 'Per favore, completa questo modulo con attenzione per richiedere un prestito!'}
      </p>

      <h3
        style={{
          color: 'green',
          fontSize: '20px',
          textAlign: 'center',
          marginTop: '10px',
          marginBottom: '-10px',
        }}
      >
        {success}
      </h3>

      <form onSubmit={(e) => e.preventDefault()} className="fromForLoan">
        <div className="loan_form">
          <div>
            <label className="">
              {langue === 'english'
                ? 'Why do you need a loan? Please provide us with some explanation'
                : 'Perché hai bisogno di un prestito? Fornisci una spiegazione'}
            </label>
            <textarea
              name="object"
              value={loanData.object}
              onChange={handleInputChange}
              id="object"
              rows="5"
              autoCapitalize="sentences"
              placeholder={
                langue === 'english' ? 'Write here...' : 'Scrivi qui...'
              }
              required
            ></textarea>
          </div>

          <div>
            <label className="">
              {langue === 'english' ? 'Full name' : 'Nome completo'}
            </label>
            <input
              type="text"
              name="name"
              value={loanData.name}
              onChange={handleInputChange}
              id="name"
              className="register-input"
              required
            />
          </div>
          <div>
            <label className="">
              {langue === 'english' ? 'Email' : 'E-mail'}
            </label>
            <input
              type="email"
              name="email"
              value={loanData.email}
              onChange={handleInputChange}
              id="email"
              className="register-input"
              required
            />
          </div>

          <div>
            <label className="">
              {langue === 'english'
                ? 'Amount borrowed (euros)'
                : 'Importo richiesto (euro)'}
            </label>
            <input
              type="text"
              name="amount"
              value={loanData.amount}
              onChange={handleInputChange}
              id="amount"
              className="register-input"
              required
            />
          </div>
          <div>
            <label className="">
              {langue === 'english'
                ? 'Borrowing period (in months)'
                : 'Periodo di prestito (in mesi)'}
            </label>
            <input
              type="text"
              name="period"
              value={loanData.period}
              onChange={handleInputChange}
              id="period"
              className="register-input"
              required
              hidden
            />
          </div>
          <div>
            <label className="">
              {langue === 'english'
                ? 'Nominal annual rate (%) :'
                : 'Tasso annuo nominale (%):'}
            </label>
            <input
              type="text"
              name="rate"
              className="register-input"
              value={
                langue === 'english'
                  ? '2.5 (this is fixed by the bank)'
                  : '2.5 (questo è fissato dalla banca)'
              }
              required
              disabled
            />
            <input type="hidden" name="name" value={loanData.name} disabled />
            <input type="hidden" name="email" value={loanData.email} disabled />
          </div>
        </div>
        {isLoading && <Loader />}
        <h3 style={{ color: 'red' }}> {error} </h3>

        <div className="btn_container loan_btn">
          <input
            type="reset"
            value={langue === 'english' ? 'Cancel' : 'Annulla'}
            className="cancel"
          />

          <input
            type="submit"
            target="_top"
            onClick={() => {
              if (checkEmptyData(loanData)) {
                handleSubmit()
              }
            }}
            className="connect_me"
            value={langue === 'english' ? 'Continue' : 'Continua'}
          />
        </div>
      </form>
    </div>
  )
}

export default LoanForm
