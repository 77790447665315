import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Context for langue
export const LangueContext = createContext()

export const LangueProvider = ({ children }) => {
  const [langue, setLangue] = useState(() => {
    const savedLangue = localStorage.getItem('langue')
    return savedLangue ? savedLangue : 'portuguais'
  })

  // Effet pour récupérer la langue du localStorage lors du chargement initial
  useEffect(() => {
    const savedLangue = localStorage.getItem('langue')
    if (savedLangue) {
      setLangue(savedLangue)
    }
  }, [])

  return (
    <LangueContext.Provider value={{ langue, setLangue }}>
      {children}
    </LangueContext.Provider>
  )
}

// Context for logging
export const LoginContext = createContext()

export const LoginProvider = ({ children }) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')

    // delete token after 2h
    // setInterval(() => {
    //   const tokenExpiration = parseInt(
    //     localStorage.getItem('tokenExpiration'),
    //     10
    //   )
    //   if (!isNaN(tokenExpiration)) {
    //     const currentTime = new Date().getTime()
    //     if (currentTime - tokenExpiration >= 2 * 60 * 60 * 1000) {
    //       // 2 heures en millisecondes
    //       // Le token a expiré, supprimer le token du stockage
    //       localStorage.removeItem('token')
    //       localStorage.removeItem('tokenExpiration')
    //     }
    //   }
    // }, 60000)
    // end

    // Si le jeton est disponible, effectuez la demande protégée
    if (token) {
      fetch('https://api-asly-1.onrender.com/api/auth/', {
        // fetch('http://127.0.0.1:8001/api/auth/', {

        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => setIsLoggedIn(true))
        .catch((error) => {
          console.error('Error fetching protected data:', error)
          // Si la demande échoue, par exemple en raison d'une authentification invalide,
          // redirect to login page
          localStorage.removeItem('token')
          localStorage.removeItem('firstName')
          localStorage.removeItem('lastName')
          localStorage.removeItem('userRole')
          localStorage.removeItem('tokenExpiration')
          setIsLoggedIn(false)
          navigate('/login')
        })
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('userRole')
      localStorage.removeItem('tokenExpiration')
      setIsLoggedIn(false)
      // navigate('/login')
    }
  }, [navigate])

  return (
    <LoginContext.Provider value={{ isLoggedIn }}>
      {children}
    </LoginContext.Provider>
  )
}
