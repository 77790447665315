import React, { useContext } from 'react'
import { LoginContext } from '../../utils/context'
import { useLangue } from '../../utils/hooks'

import './accountData.css'

function AccountData() {
  const { langue } = useLangue()
  const { isLoggedIn } = useContext(LoginContext)

  return (
    isLoggedIn && (
      <div className="data_container" langue={langue}>
        <div className="datas">
          <h2>
            {langue === 'english' ? 'Account Number :' : 'Número da Conta :'}
          </h2>
          <p>4539 0979 184</p>
        </div>
        <div className="datas">
          <h2>{langue === 'english' ? 'NIB :' : 'NIB :'}</h2>
          <p>0033 0000 4539 0979 184</p>
        </div>
        <div className="datas">
          <h2>{langue === 'english' ? 'IBAN :' : 'IBAN :'}</h2>
          <p>AS50 0033 0000 4539 0979 184</p>
        </div>
        <div className="datas">
          <h2>{langue === 'english' ? 'BIC / SWIFT :' : 'BIC / SWIFT :'}</h2>
          <p>BCOMASPL</p>
        </div>
      </div>
    )
  )
}

export default AccountData
