import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Loader } from '../../utils/style/loader'
// import { useFetch } from '../../utils/hooks'
import './profile.css'
import { useLangue } from '../../utils/hooks'

import IdPicture from '../../assets/profile/nopicture.jpg'

function Profile() {
  const { langue } = useLangue()
  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      fetch(`https://api-asly-1.onrender.com/api/auth/profile`, {
        // fetch(`http://162.254.32.225:8001/api/auth/profile`, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`, // Inclure le token JWT dans l'en-tête Authorization
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setUserData(data)
          setIsLoading(false) // Mettre isLoading à false une fois les données chargées
        })
        .catch((error) => {
          console.error('Error fetching user data:', error)
          setIsLoading(false) // isLoading à false même en cas d'erreur
        })
    } else {
      localStorage.removeItem('token')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('userRole')
      localStorage.removeItem('tokenExpiration')
      navigate('/login')
    }
  }, [token, navigate]) // token dans les dépendances pour recharger les données lorsque user token change

  return (
    <div className="ProfileWrapper" langue={langue}>
      <div className="contact_top">
        <div className="contact_top_color">
          <h1 className="contact_top_h1">
            {langue === 'english' ? 'Profile' : 'Perfil'}
          </h1>
          <div className="contact_top_div">
            <Link to="/user_dashbord" className="home_link">
              {langue === 'english' ? 'Home /' : 'Início /'}
            </Link>
            <p>{langue === 'english' ? 'Profile' : 'Perfil'}</p>
          </div>
        </div>
      </div>
      {isLoading ? (
        <div style={{ margin: '145px 0', width: '100%' }}>
          <Loader />
        </div>
      ) : (
        <div>
          <div className="profil_content border profil_info ">
            <div className="profil_content_1">
              <div className="border">
                <h2>
                  {langue === 'english' ? 'Profile picture' : 'Foto de perfil'}
                </h2>
                <div className="picture">
                  <h1>
                    {userData.firstName[0]}
                    {userData.lastName[0]}
                  </h1>
                </div>
              </div>
              <div className="border">
                <h2>ID card</h2>
                <div className="cardID idpicture">
                  <img
                    src={IdPicture}
                    alt={
                      langue === 'english'
                        ? 'ID PICTURE HERE'
                        : 'FOTO DO DOCUMENTO DE IDENTIFICAÇÃO AQUI'
                    }
                    className="id_picture"
                  />
                </div>
              </div>
            </div>
            <div className="profil_content_2 border">
              <div className="profil_title">
                <h2>
                  {langue === 'english'
                    ? 'Personal informations'
                    : 'Informações pessoais'}
                </h2>
                <Link to="/update_user_profile" style={{ color: '#1d4ed8' }}>
                  {langue === 'english' ? 'Edit' : 'Editar'}
                </Link>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english' ? 'Civility :' : 'Civilidade :'}
                </p>
                <p className="label_value"> {userData.civility}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english' ? 'Full Name :' : 'Nome completo :'}
                </p>
                <p className="label_value">
                  {userData.firstName} {userData.lastName}
                </p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Date of birth :'
                    : 'Data de nascimento :'}
                </p>
                <p className="label_value">{userData.birthday}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'E-mail address :'
                    : 'Endereço de e-mail :'}
                </p>
                <p className="label_value">{userData.email}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english'
                    ? 'Phone number :'
                    : 'Número de telefone :'}
                </p>
                <p className="label_value">{userData.phone}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english' ? 'Marital status :' : 'Estado civil :'}
                </p>
                <p className="label_value">{userData.marital}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english' ? 'Occupation :' : 'Ocupação :'}
                </p>
                <p className="label_value">{userData.occupation}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english' ? 'Country :' : 'País :'}
                </p>
                <p className="label_value">{userData.country}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english' ? 'City :' : 'Cidade :'}
                </p>
                <p className="label_value">{userData.city}</p>
              </div>
              <div className="row">
                <p className="label">
                  {langue === 'english' ? 'Address :' : 'Endereço :'}
                </p>
                <p className="label_value">{userData.address}</p>
              </div>
            </div>
          </div>
          <div className="profil_content_3 border">
            <div className="profil_title">
              <h2>{langue === 'english' ? 'Security' : 'Segurança'}</h2>
              <Link to="/profile" style={{ color: '#1d4ed8' }}>
                {langue === 'english' ? 'Edit' : 'Editar'}
              </Link>
            </div>
            <div className="row">
              <p className="label">
                {langue === 'english' ? 'Password :' : 'Senha :'}
              </p>
              <p>***********</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Profile
