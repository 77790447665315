import React from 'react'
import { useLangue } from '../../utils/hooks'
import { testimonialsList } from '../../data/testimonials'
import '../Testimonials/testimonial.css'

function Testimonials() {
  const { langue } = useLangue()
  return (
    <div className="testimonial" langue={langue}>
      <h1 className="testimonial_h1">
        {langue === 'english' ? 'Testimonials' : 'Depoimentos'}
      </h1>
      <div className="testimonial_h1_bottom"></div>

      <div className="responsive-container-block bg">
        {/* <p className="text-blk title">Testimonials</p> */}
        <div className="btn">
          <div className="container-block swiper-button-prevs">
            <img
              className="image-block pagination-button"
              src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/t33.png"
              alt="btn"
            />
          </div>
          <div className="container-block swiper-button-nexts">
            <img
              className="image-block pagination-button"
              src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/t32.png"
              alt="btn"
            />
          </div>
        </div>
        <div className="swiper-container mySwiper">
          <div className="swiper-wrapper">
            {testimonialsList.map((testimonial, index) => (
              <div
                className="swiper-slide"
                key={`${testimonial.name}-${index}`}
              >
                <div className="responsive-container-block content">
                  <p className="text-blk quotes">“</p>
                  <img
                    src={testimonial.picture}
                    alt="Testimonials"
                    className="img"
                  />
                  <p className="text-blk info">
                    {langue === 'english'
                      ? testimonial.description
                      : testimonial.description_pt}
                  </p>
                  <img
                    className="image-block review"
                    src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/t82.jpg"
                    alt="dot"
                  />
                  <p
                    className="text-blk name"
                    style={{
                      margin: '8px',
                      fontSize: '22px',
                      fontWeight: '600',
                    }}
                  >
                    {testimonial.name}
                  </p>
                  <p
                    className="text-blk desig"
                    style={{
                      color: '#64748b',
                    }}
                  >
                    {langue === 'english'
                      ? testimonial.profil
                      : testimonial.profil_pt}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="swiper-pagination container-block"></div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
