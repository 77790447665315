import React from 'react'
import { Link } from 'react-router-dom'
import './emailVErify.css'
import { useLangue } from '../../utils/hooks'

function EmailVerify() {
  const { langue } = useLangue()

  return (
    <div className="emailVerify_container" langue={langue}>
      <div className="emailVerify_sub_container">
        <div className="emailVerify_left">
          <h1 className="emailVerify-title-1">
            {langue === 'english' ? 'Asly Bank' : 'Banco Asly'}
          </h1>
          <h2 className="emailVerify-title-2">
            {langue === 'english'
              ? 'For your satisfaction'
              : 'Per la tua soddisfazione'}
          </h2>
          <h3 className="emailVerify-title-3">
            {langue === 'english'
              ? 'Welcome to Asly Bank!'
              : 'Benvenuto in Banco Asly!'}
          </h3>
          <p className="emailVerify-title-message">
            {langue === 'english'
              ? `We are delighted to welcome you among us. Your new account opens the doors to our banking services adapted to your needs. Explore our online platform, carry out your transactions securely, and benefit from exclusive advantages. Need help? Our team is here for you. Thank you for trusting Asly Bank. We are here to help you achieve your financial goals. Please check your spam if you do not receive the validation link for your account.`
              : `Siamo lieti di darti il benvenuto tra noi. Il tuo nuovo conto apre le porte ai nostri servizi bancari adattati alle tue esigenze. Esplora la nostra piattaforma online, effettua le tue transazioni in sicurezza e approfitta di vantaggi esclusivi. Hai bisogno di aiuto? Il nostro team è qui per te. Grazie per aver fiducia in Banco Asly. Siamo qui per aiutarti a raggiungere i tuoi obiettivi finanziari. Controlla la tua cartella spam se non ricevi il link di validazione per il tuo account.`}
          </p>
        </div>
        <div className="message_display">
          <h1>
            {langue === 'english'
              ? 'Verifying your email address'
              : 'Verifica del tuo indirizzo email'}
          </h1>
          <Link className="link">
            {langue === 'english'
              ? 'Resend the verification email'
              : "Rispedisci l'email di verifica"}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default EmailVerify
