import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './header.css'
import { useLangue } from '../../utils/hooks'

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material'

function Header() {
  const { langue } = useLangue()

  const navigate = useNavigate()
  let token = localStorage.getItem('token')

  const [isLoggedIn, setIsLoggedIn] = useState(token)
  const [isAdmin, setIsAdmin] = useState(false)

  const [showMenu, setShowMenu] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    localStorage.removeItem('userRole')
    localStorage.removeItem('tokenExpiration')
    setIsLoggedIn(false)
    navigate('/')
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  let lastName = localStorage.getItem('lastName')
  let firstName = localStorage.getItem('firstName')

  useEffect(() => {
    document.title = 'Asly'
  })

  useEffect(() => {
    const userRole = localStorage.getItem('userRole')

    if (userRole === 'false') {
      setIsAdmin(false)
    } else {
      setIsAdmin(true)
    }
  }, [])

  return (
    <div langue={langue}>
      {!isLoggedIn ? (
        <nav className="navbar">
          <div className="navbar-container">
            <div
              className={`menu-toggle ${showMenu ? 'active' : ''}`}
              onClick={toggleMenu}
            >
              <div className="bar"> </div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>

            <div className="logo_menu">
              <a className="title" href="/">
                A-SLY
              </a>

              <div className={`menu ${showMenu ? 'active' : ''} `}>
                <div className="part_1">
                  <a href="/" className="link">
                    {langue === 'english' ? 'Home' : 'Inizio'}
                  </a>
                  <a
                    href="/"
                    className="link asly clicOnAsly"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {langue === 'english' ? 'A-sly' : 'A-sly'}
                    <div className="sousmenu">
                      <a className="link" href="/about">
                        {langue === 'english' ? 'About' : 'Chi siamo'}
                      </a>
                      <a className="link" href="/insurance">
                        {langue === 'english' ? 'Insurance' : 'Assicurazione'}
                      </a>
                      <a className="link" href="/team">
                        {langue === 'english' ? 'Team' : 'Squadra'}
                      </a>
                    </div>
                  </a>
                  <a className="link" href="/services">
                    {langue === 'english' ? 'Services' : 'Servizi'}
                  </a>
                  <a className="link" href="/contact">
                    {langue === 'english' ? 'Contact' : 'Contatto'}
                  </a>
                </div>
              </div>
            </div>

            <div className="part_2">
              <a className="link" href="/login">
                {langue === 'english' ? 'Connection' : 'Connessione'}
              </a>

              <a className="link" href="/signup">
                {langue === 'english' ? 'Registration' : 'Registrazione'}
              </a>
            </div>
          </div>
        </nav>
      ) : (
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              <Link
                to="/user_dashbord"
                style={{
                  textDecoration: 'none',
                  color: '#ffffff',
                  fontSize: '27px',
                  fontWeight: '600',
                }}
              >
                A-SLY
              </Link>
            </Typography>
            <div style={{ marginRight: '10px', fontSize: '20px' }}>
              {/* {firstName} {lastName} */}
            </div>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleClick}
            >
              <div
                style={{
                  height: '45px',
                  width: '45px',
                  borderRadius: '100%',
                  backgroundColor: '#ffffff',
                }}
              >
                <p
                  style={{
                    position: 'relative',
                    top: '50%',
                    left: '50%',
                    translate: '-50% -50%',
                  }}
                >
                  {firstName[0]}
                  {lastName[0]}
                </p>
              </div>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {isAdmin ? (
                <MenuItem onClick={handleClose}>
                  <Link to="/display_users" style={{ textDecoration: 'none' }}>
                    <ion-icon name="home-outline"></ion-icon>
                    {langue === 'english' ? 'Dashbord' : 'Pannello'}
                  </Link>
                </MenuItem>
              ) : (
                <MenuItem onClick={handleClose}>
                  <Link to="/user_dashbord" style={{ textDecoration: 'none' }}>
                    <ion-icon name="home-outline"></ion-icon>
                    {langue === 'english' ? 'Dashbord' : 'Pannello'}
                  </Link>
                </MenuItem>
              )}
              <MenuItem onClick={handleClose}>
                <Link to="/profile" style={{ textDecoration: 'none' }}>
                  <ion-icon name="person"></ion-icon>
                  {langue === 'english' ? 'Profile' : 'Profilo'}
                </Link>
              </MenuItem>
              {!isAdmin && (
                <>
                  <MenuItem onClick={handleClose}>
                    <Link to="/loan" style={{ textDecoration: 'none' }}>
                      <ion-icon name="accessibility"></ion-icon>
                      {langue === 'english'
                        ? 'Savings / Loan'
                        : 'Risparmio / Prestito'}
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleClose}>
                    <Link to="/bank_account" style={{ textDecoration: 'none' }}>
                      <ion-icon name="accessibility"></ion-icon>
                      {langue === 'english' ? 'Bank Account' : 'Conto bancario'}
                    </Link>
                  </MenuItem>

                  <MenuItem onClick={handleClose}>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                      <ion-icon name="headset"></ion-icon>
                      {langue === 'english' ? 'Contact 24/7' : 'Contatto 24/7'}
                    </Link>
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={handleLogout}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <ion-icon name="exit-outline"></ion-icon>
                  {langue === 'english' ? 'Logout' : 'Esci'}
                </Link>
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      )}
    </div>
  )
}

export default Header
