import blog_1 from '../assets/insurance/blog-1.jpg'
import blog_2 from '../assets/insurance/blog-2.jpg'
import blog_3 from '../assets/insurance/blog-3.jpg'
import blog_4 from '../assets/insurance/blog-4.jpg'
import blog_5 from '../assets/insurance/blog-5.jpg'
import blog_6 from '../assets/insurance/blog-6.jpg'

export const insuranceList = [
  {
    title: 'Death Disability Insurance (ADI)',
    description:
      'Death and Disability Insurance (ADI) represents crucial security for the borrower and their loved ones. In the event of death or permanent disability, this insurance covers the repayment of the loan balance, thus alleviating the financial burden that could weigh on the family in these difficult times. It provides peace of mind by ensuring that the debt incurred does not become an additional financial burden in tragic circumstances. ',
    picture: blog_1,
    title_pt: 'Assicurazione per morte e invalidità (ADI)',
    description_pt:
      "L'assicurazione per morte e invalidità (ADI) rappresenta una sicurezza essenziale per il mutuatario e per i suoi familiari. In caso di morte o invalidità permanente, questa assicurazione copre il rimborso del saldo del prestito, alleviando così l'onere finanziario che potrebbe gravare sulla famiglia in questi momenti difficili. Fornisce tranquillità garantendo che il debito contratto non diventi un ulteriore onere finanziario in circostanze tragiche.",
  },
  {
    title: 'Job Loss Insurance',
    description:
      'Job Loss insurance offers protection in times of professional uncertainty. In the event of involuntary unemployment, it intervenes by covering the monthly loan payments for a predetermined period. This gives the borrower a financial buffer period, allowing a smoother transition to new professional opportunities without compromising financial stability. ',
    picture: blog_2,
    title_pt: 'Assicurazione contro la perdita del lavoro',
    description_pt:
      "L'assicurazione contro la perdita del lavoro offre protezione in tempi di incertezza professionale. In caso di disoccupazione involontaria, interviene coprendo i pagamenti mensili del prestito per un periodo predefinito. Questo dà al mutuatario un periodo di protezione finanziaria, permettendo una transizione più agevole verso nuove opportunità professionali senza compromettere la stabilità finanziaria.",
  },
  {
    title: 'Critical Illness Insurance',
    description:
      'Critical Illness insurance provides financial security in the event of diagnosis of a serious illness stipulated in the contract. By offering a lump sum payment or covering monthly loan payments, it aims to alleviate the financial pressures associated with medical costs and loss of income resulting from serious illness. It is a lifeline that allows the borrower to focus on recovery without worrying about the immediate financial implications. ',
    picture: blog_3,
    title_pt: 'Assicurazione per malattie gravi',
    description_pt:
      "L'assicurazione per malattie gravi garantisce sicurezza finanziaria in caso di diagnosi di una malattia grave prevista nel contratto. Offrendo un pagamento una tantum o coprendo i pagamenti mensili del prestito, mira ad alleviare le pressioni finanziarie associate ai costi medici e alla perdita di reddito derivante da una malattia grave. È un'ancora di salvezza che permette al mutuatario di concentrarsi sulla guarigione senza preoccuparsi delle implicazioni finanziarie immediate.",
  },
  {
    title: 'Permanent Total Disability Insurance (IPT)',
    description:
      'Permanent Total Disability insurance covers permanent incapacity to work. It guarantees repayment of the loan balance, providing crucial financial protection to the borrower and their family. This insurance provides peace of mind by ensuring that, even if you are unable to earn an income, financial commitments are taken care of. ',
    picture: blog_4,
    title_pt: 'Assicurazione per invalidità totale permanente (IPT)',
    description_pt:
      "L'assicurazione per invalidità totale permanente copre l'incapacità permanente al lavoro. Garantisce il rimborso del saldo del prestito, fornendo una protezione finanziaria cruciale al mutuatario e alla sua famiglia. Questa assicurazione fornisce tranquillità garantendo che, anche in caso di incapacità di guadagnare un reddito, gli impegni finanziari siano soddisfatti.",
  },
  {
    title: 'Temporary Total Incapacity Insurance (ITT) ',
    description:
      'Total Temporary Disability insurance is a lifeline in the event of temporary incapacity resulting from illness or injury. By covering the monthly loan payments during this period, it provides a financial safety net, allowing the borrower to recover without experiencing the financial pressures associated with being temporarily unable to work. ',
    picture: blog_5,
    title_pt: 'Assicurazione per incapacità temporanea totale (ITT)',
    description_pt:
      "L'assicurazione per incapacità temporanea totale è un'ancora di salvezza in caso di incapacità temporanea derivante da malattia o infortunio. Coprendo i pagamenti mensili del prestito durante questo periodo, fornisce una rete di sicurezza finanziaria, permettendo al mutuatario di recuperare senza subire le pressioni finanziarie associate all'incapacità temporanea di lavorare.",
  },
  {
    title: 'Partial Unemployment Insurance',
    description:
      'Partial Unemployment insurance intervenes during periods of reduced working hours or partial unemployment. It covers a portion of the loan payments, providing financial support during these periods of professional transition. This insurance is designed to help the borrower maintain financial stability even when their income is partially affected by difficult economic circumstances. ',
    picture: blog_6,
    title_pt: 'Assicurazione per disoccupazione parziale',
    description_pt:
      "L'assicurazione per disoccupazione parziale interviene durante i periodi di riduzione dell'orario di lavoro o di disoccupazione parziale. Copre una parte dei pagamenti del prestito, fornendo supporto finanziario durante questi periodi di transizione professionale. Questa assicurazione è progettata per aiutare il mutuatario a mantenere la stabilità finanziaria anche quando il suo reddito è parzialmente influenzato da circostanze economiche difficili.",
  },
]
