import cards_1 from '../assets/services/cards-1.jpg'
import cards_2 from '../assets/services/cards-2.jpg'
import cards_3 from '../assets/services/cards-3.jpg'
import cards_4 from '../assets/services/cards-4.jpg'
import cards_5 from '../assets/services/cards-5.jpg'
import cards_6 from '../assets/services/cards-6.jpg'
import cards_7 from '../assets/services/cards-7.jpg'
import cards_8 from '../assets/services/cards-8.jpg'

export const servicesList = [
  {
    title: 'Personal loan',
    description:
      'The personal loan facilitates the financing of projects without initial contribution. The repayment period, adapted to your financial capacity, can extend over several months to several years.',
    picture: cards_1,
    description_pt:
      'Il prestito personale facilita il finanziamento di progetti senza contributo iniziale. Il periodo di rimborso, adattato alla tua capacità finanziaria, può estendersi da alcuni mesi a diversi anni.',
  },
  {
    title: 'Real Estate Loan',
    description:
      'The real estate credit, or real estate loan, is a loan intended to finance all or part of the acquisition of real estate, the construction operation; or work on such property. Real estate credit is regulated by law.',
    picture: cards_2,
    description_pt:
      "Il credito immobiliare, o mutuo immobiliare, è un prestito destinato a finanziare, in tutto o in parte, l'acquisizione di un immobile, l'operazione di costruzione o la realizzazione di lavori su tale proprietà. Il credito immobiliare è regolato dalla legge.",
  },
  {
    title: 'Car Loan',
    description:
      'Auto credit, a personal loan dedicated to the purchase of your car, offers rates varying depending on the amount, duration of the credit, and type of vehicle. (rates may be different for a new car compared to a used car).',
    picture: cards_3,
    description_pt:
      "Il credito auto, un prestito personale dedicato all'acquisto della tua auto, offre tassi che variano in base all'importo, alla durata del credito e al tipo di veicolo. (i tassi possono essere diversi per un'auto nuova rispetto a un'auto usata).",
  },
  {
    title: 'Investment Loan',
    description:
      'An investment loan is a form of financing dedicated to business projects such as the purchase of equipment or construction. Conditions adapted to long-term repayment, promoting growth and competitiveness.',
    picture: cards_4,
    description_pt:
      "Un prestito di investimento è una forma di finanziamento destinata a progetti aziendali come l'acquisto di attrezzature o la costruzione. Condizioni adattate al rimborso a lungo termine, promuovendo la crescita e la competitività.",
  },
  {
    title: 'Debt Consolidation Loan',
    description:
      'A debt consolidation loan consolidates several debts into one, simplifying payments with a potential lower interest rate.',
    picture: cards_5,
    description_pt:
      'Un prestito di consolidamento del debito consolida vari debiti in uno, semplificando i pagamenti con un potenziale tasso di interesse più basso.',
  },
  {
    title: 'Mortgage',
    description:
      'A mortgage loan is a form of borrowing secured by a property, allowing the purchase of real estate with repayment over a defined period.',
    picture: cards_6,
    description_pt:
      "Un prestito ipotecario è una forma di prestito garantito da una proprietà, che consente l'acquisto di immobili con rimborso su un periodo definito.",
  },
  {
    title: 'Loan Repurchase',
    description:
      'A buyout loan consolidates various loans into one, simplifying payments with potentially more favorable terms.',
    picture: cards_7,
    description_pt:
      'Un prestito di riacquisto consolida vari prestiti in uno, semplificando i pagamenti con condizioni potenzialmente più favorevoli.',
  },
  {
    title: 'Asly Bank',
    description:
      'Insurance for your Asly Bank home loan Fixed amount of contributions excluding tax for the entire duration of your loan. Loyalty rewarded: 20% reduction for any second membership to Asly Bank. ',
    picture: cards_8,
    description_pt:
      "Assicurazione per il tuo mutuo Asly Bank Importo fisso dei contributi esclusi fiscali per l'intera durata del prestito. Fedeltà premiata: riduzione del 20% per qualsiasi seconda adesione a Asly Bank.",
  },
]
