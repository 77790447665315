import React from 'react'
import { Link } from 'react-router-dom'
import { insuranceList } from '../../data/insurance'
import './insurance.css'
import { useLangue } from '../../utils/hooks'

function Insurance() {
  const { langue } = useLangue()
  return (
    <div className="InsuranceWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {langue === 'english' ? 'Insurance' : 'Seguro'}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {langue === 'english' ? 'Home /' : 'Inizio /'}
            </Link>
            <p>{langue === 'english' ? 'Insurance' : 'Seguro'}</p>
          </div>
        </div>
      </div>
      <div className="insurance_bottom">
        {insuranceList.map((it, index) => (
          <div className="insurance_bottom_item" key={`${it.title}-${index}`}>
            <img src={it.picture} alt="img" />
            <h3>{langue === 'english' ? it.title : it.title_pt}</h3>
            <p>{langue === 'english' ? it.description : it.description_pt}</p>
            <Link className="read_more_link" to="/insurance">
              {langue === 'english' ? 'Read more →' : 'Leer más →'}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Insurance
