import React from 'react'
import { Link } from 'react-router-dom'
import './bankAccount.css'
import { useLangue } from '../../utils/hooks'

function BankAccount() {
  const { langue } = useLangue()
  return (
    <div className="bankAccountWrapper" langue={langue}>
      <div className="insurance_top">
        <div className="insurance_top_color">
          <h1 className="insurance_top_h1">
            {langue === 'english' ? 'Bank account' : 'Conto Bancario'}
          </h1>
          <div className="insurance_top_div">
            <Link to="/" className="home_link">
              {langue === 'english' ? 'Home /' : 'Início /'}
            </Link>
            <p>{langue === 'english' ? 'Bank account' : 'Conto Bancario'}</p>
          </div>
        </div>
      </div>
      <div className="bankAccount">
        <h1 className="bankAccount_h1">
          {langue === 'english'
            ? 'Bank Account - Asly Online Banking'
            : 'Conto Bancario - Banca Online Asly'}
        </h1>
        <div className="bankAccount_h1_bottom"></div>
        <div className="bankAccount_content">
          <div>
            <p>
              {langue === 'english'
                ? 'Welcome to the page dedicated to the bank accounts of our financial institution. Opening an account with us offers a variety of benefits tailored to your financial needs. Explore our different account options to find out how we can help you manage your finances.'
                : 'Benvenuti alla pagina dedicata ai conti bancari della nostra istituzione finanziaria. Aprire un conto con noi offre una varietà di vantaggi su misura per le vostre esigenze finanziarie. Esplorate le nostre diverse opzioni di conto per scoprire come possiamo aiutarvi a gestire le vostre finanze.'}
            </p>
          </div>

          <div>
            <h3>
              {langue === 'english' ? 'Account Types' : 'Tipologie di Conto'}
            </h3>
            <h2>
              1. {langue === 'english' ? 'Current account' : 'Conto Corrente'}
            </h2>
            <p>
              {langue === 'english'
                ? 'The current account is designed to meet your daily financial management needs. With no account maintenance fees, it offers maximum flexibility for your daily transactions. Discover the advantages of this account and the opening conditions.'
                : 'Il conto corrente è progettato per soddisfare le vostre esigenze di gestione finanziaria quotidiana. Senza spese di manutenzione del conto, offre massima flessibilità per le vostre transazioni quotidiane. Scoprite i vantaggi di questo conto e le condizioni di apertura.'}
            </p>

            <h2>
              2. {langue === 'english' ? 'Savings account' : 'Conto Risparmio'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Our savings account allows you to grow your money with competitive interest rates. Learn more about the benefits, withdrawal methods and conditions for opening this account.'
                : 'Il nostro conto risparmio vi permette di far crescere il vostro denaro con tassi di interesse competitivi. Scoprite di più sui benefici, i metodi di prelievo e le condizioni per aprire questo conto.'}
            </p>

            <h2>
              3.{' '}
              {langue === 'english'
                ? 'Young Checking Account'
                : 'Conto Corrente Giovani'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Intended for young professionals, the Youth Checking Account offers specific advantages to support your first steps in the financial world. Learn about the unique features and eligibility requirements.'
                : 'Destinato ai giovani professionisti, il Conto Corrente Giovani offre vantaggi specifici per supportare i vostri primi passi nel mondo finanziario. Scoprite le caratteristiche uniche e i requisiti di idoneità.'}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Opening Procedure'
                : 'Procedura di Apertura'}
            </h2>
            <p>
              {langue === 'english'
                ? 'Opening an account with us is quick and easy. Follow our step-by-step guide to find out what documents are needed, online or in-branch options, and how long it takes to activate your account.'
                : "Aprire un conto con noi è rapido e semplice. Seguite la nostra guida passo dopo passo per scoprire quali documenti sono necessari, le opzioni online o presso l'agenzia e quanto tempo ci vuole per attivare il vostro conto."}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english' ? 'General Benefits' : 'Benefici Generali'}
            </h2>
            <p>
              {langue === 'english'
                ? 'By choosing our institution, you benefit from many advantages, such as the security of your funds, convenient access to our online services, dedicated customer service and much more. Find out what makes our bank the ideal partner for your financial needs.'
                : "Scegliendo la nostra istituzione, beneficerete di molti vantaggi, come la sicurezza dei vostri fondi, l'accesso comodo ai nostri servizi online, un servizio clienti dedicato e molto altro ancora. Scoprite cosa rende la nostra banca il partner ideale per le vostre esigenze finanziarie."}
            </p>
          </div>

          <div>
            <h2>
              {langue === 'english'
                ? 'Frequently Asked Questions (FAQ)'
                : 'Domande Frequenti (FAQ)'}
            </h2>
            <p>
              {langue === 'english'
                ? 'To answer your frequently asked questions, consult our FAQ section dedicated to bank accounts. Find quick answers to the most common questions and get the information you need.'
                : 'Per rispondere alle vostre domande frequenti, consultate la nostra sezione FAQ dedicata ai conti bancari. Trovate risposte rapide alle domande più comuni e ottenete le informazioni di cui avete bisogno.'}
            </p>
          </div>

          <div>
            <h2>{langue === 'english' ? 'Contact' : 'Contatto'}</h2>
            <p>
              {langue === 'english'
                ? 'If you have additional questions or require personalized assistance, please do not hesitate to contact us. Our team is here to help you choose the account that best suits your needs.'
                : 'Se avete domande aggiuntive o necessitate di assistenza personalizzata, non esitate a contattarci. Il nostro team è qui per aiutarvi a scegliere il conto che meglio si adatta alle vostre esigenze.'}
            </p>
            <p>
              {langue === 'english'
                ? 'Open the door to simple and efficient financial management by choosing one of our bank accounts. We are delighted to support you on your financial journey.'
                : 'Aprite le porte a una gestione finanziaria semplice ed efficiente scegliendo uno dei nostri conti bancari. Siamo lieti di supportarvi nel vostro percorso finanziario.'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankAccount
